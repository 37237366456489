import React from "react";
import Slideshow from "./Slideshow";

export const Apps = (props) => {
  return (
    <div id="apps">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="apps-text">
              <h2>Apps</h2>
              <p>
                {props.data ? props.data.paragraph : "loading..."}
                <br />
                Download and play <b>Catch the VIP</b> for <b>free</b> to Android
                <a href="https://play.google.com/store/apps/details?id=com.hcruzp.pushingvips" target="_blank">
                  <b> here</b>
                </a>
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <Slideshow data={props.data ? props.data.images : []} />
          </div>
        </div>
      </div>
    </div>
  );
};
