import React, { useState, useEffect } from 'react';

function Slideshow(props) {
  // An array of images
  const images = props.data;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);  // Change image every 10 seconds

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [currentImageIndex, images.length]);  // The effect depends on currentImageIndex and images.length

  return (
    <div>
      <img src={images[currentImageIndex]} className="img-responsive" alt="" />
    </div>
  );
}

export default Slideshow;
